// INFO: 지재권 게시판 등록
<script>
import { getLabPatent } from '@/api/platform/community'
import SearchBarVue from '@/components/ui/SearchBar.vue' // 검색 구역 공통 UI
import LoadingVue from '@/components/ui/Loading.vue'
import { GridComponent, ColumnsDirective, Sort, Toolbar, Search, Page } from '@syncfusion/ej2-vue-grids'
import { searchPatentAll, searchPatentConditions, searchPatent } from '@/api/admin/ntis/ntis'
import { patentInsert } from '@/api/admin/ntis/ntisPatentAPI'

import v2FileApi from '@/api/v2FileApi'

export default {
  name: 'adminBoardPatentCreate',
  components: {
    SearchBarVue,
    LoadingVue,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective
  },
  data() {
    return {
      isLoading: false,
      labNo: '',
      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'], // 툴바에 검색 옵션

      // ********** 검색 **********
      patentSearchConditions: searchPatentConditions.slice(1, searchPatentConditions.length), // 논문 검색 옵션
      searchedPatentData: [], // 검색 결과 데이터
      selectedPatentData: {}, // 검색 결과에서 선택된 데이터
      key: null, // 과제고유번호

      // ********** 사용자 입력 데이터 **********
      userInputData: {},

      stringSelectOption: '',
      searchInputList: [],
      registeredKeys: []
    }
  },
  provide: {
    grid: [Sort, Toolbar, Search, Page]
  },
  methods: {
    // ********** 지재권 검색 버튼 **********
    async patentSearchResult(keyword) {
      this.isLoading = true
      const { LIST } = await searchPatentAll({ addQuery: `CN=${keyword}` })
      this.searchedPatentData = LIST

      this.isLoading = false
    },

    // ********** 지재권 검색 결과 그리드 행 선택 시 이벤트 **********
    async patentSearchReaultSelected(args) {
      if (this.registeredKeys.includes(args.data.patentResultID)) {
        return alert('이미 등록된 지재권입니다.')
      }

      this.isLoading = true
      // 선택된 행의 과제고유번호 key 로 등록
      this.key = args.data.patentResultID
      this.selectedPatentData = await searchPatent(args.data.patentResultID)

      this.isLoading = false
    },

    // ********** 목록 버튼 **********
    goPatentList() {
      this.$router.push({
        path: '/admin/board/patent'
      })
    },

    // ********** 저장 버튼 **********
    async savePatent() {
      const attachedFiles = this.$refs.attachFile.files
      const maxAllowedSize = 5 * 1024 * 1024

      // 파일용량 제한
      if (attachedFiles[0]?.size > maxAllowedSize) {
        return alert('Error: 5MB 이하의 파일만 업로드 가능합니다.')
      }
      // validation
      if (!this.labNo) return alert('Error: 연구실 번호를 입력하세요.')
      if (!this.selectedPatentData) return alert('Error: 저장할 정보가 없습니다.')

      // 저장할 지재권 데이터
      const patentData = { ...this.selectedPatentData, ...this.userInputData, labNo: this.labNo }
      patentData.patentYear = new Date(patentData.patentYear.toString())
      patentData.patentProjectYear = new Date(patentData.patentProjectYear.toString())

      const result = await patentInsert(patentData)
      if (result.code) return alert(result.message)

      if (attachedFiles.length) {
        const { patentId } = result
        const uploader = new v2FileApi({ boardName: 'patent', boardId: patentId })
        await uploader.uploadFiles(attachedFiles, 'files')
      }

      alert('지재권이 저장되었습니다.')
      return this.goPatentList()
    },

    async searchPatent() {
      const query = this.buildQueryString(this.searchInputList)

      this.isLoading = true
      const { LIST } = await searchPatentAll({ addQuery: encodeURIComponent(query), displayCnt: 1000 })
      this.searchedPatentData = LIST

      this.isLoading = false
    },

    selectOptionsToString() {
      return this.patentSearchConditions.reduce((prev, cur, index) => (index === 0 ? cur.text : `${prev} / ${cur.text}`), '')
    },

    buildQueryString(list) {
      let str = ''

      for (const input of list) {
        if (!input.value) continue
        str += str === '' ? '' : '&'
        str += `${input.dataset.key}=${input.value}`
      }

      return str
    }
  },
  async mounted() {
    this.stringSelectOption = this.selectOptionsToString()
    this.searchInputList = this.$refs.form.search

    const user = this.$store.state.userStore.info
    if (user.groupName === 'lab') {
      this.labNo = user.labNo
      this.$refs.form.labNo.setAttribute('readonly', true)
    }

    this.registeredKeys = (await getLabPatent())?.items.map((item) => item.patentResultID) ?? []
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <div class="adminTable">
    <form action="#" method="post" id="patentCreateForm" ref="form">
      <div id="searchArea">
        <table class="wf__patent-search-table">
          <colgroup>
            <col width="15%" />
            <col width="85%" />
          </colgroup>
          <tbody>
            <tr>
              <th>연구실 번호</th>
              <td class="flex-td">
                <input type="number" v-model="labNo" name="labNo" />
              </td>
            </tr>
            <tr>
              <th>성과번호</th>
              <td class="flex-td">
                <SearchBarVue :style="{ width: '50%', 'justify-content': 'flex-start' }" @search="patentSearchResult" />
              </td>
            </tr>
            <tr>
              <th>{{ stringSelectOption }}</th>
              <td class="flex-td patent_search_input">
                <label class="input-wrapper" v-for="(condition, i) in patentSearchConditions" :key="i">
                  <input type="text" :placeholder="condition.text" name="search" :data-key="condition.value"
                    @keyup.enter="searchPatent" />

                  <button v-if="i === patentSearchConditions.length - 1" type="button" class="paperSearchBtn"
                    @click="searchPatent">
                    <span class="material-icons-outlined"> search </span>
                  </button>
                </label>

                <span style="margin-left: 15px; width: 230px">1000건 이하만 검색 가능합니다.</span>
              </td>
            </tr>
          </tbody>
        </table>
        <br />

        <div class="col-lg-12 control-section">
          <ejs-grid :dataSource="searchedPatentData" :allowSorting="true" :allowPaging="true" :pageSettings="pageSettings"
            height="200" :toolbar="toolbarOptions" :rowSelected="patentSearchReaultSelected">
            <e-columns>
              <e-column field="patentNo" headerText="번호" :disableHtmlEncode="false" width="80"
                textAlign="center"></e-column>
              <e-column field="patentResultID" headerText="성과번호" :disableHtmlEncode="false" width="80" :visible="false"
                textAlign="center"></e-column>
              <e-column field="patentResultTitle" headerText="출원등록명" :disableHtmlEncode="false" width="500"
                textAlign="center"></e-column>
              <e-column field="patentRegistCountry" headerText="출원등록국" :disableHtmlEncode="false" width="120"
                textAlign="center"></e-column>
              <e-column field="patentRegistNumber" headerText="출원등록번호" :disableHtmlEncode="false" width="150"
                textAlign="center"></e-column>
              <e-column field="patentRegistrant" headerText="출원등록인" :disableHtmlEncode="false" width="180"
                textAlign="center"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <br />

        <!-- <div class="e-statustext" v-if="selectedPatentData?.patentRegistNumber">
          출원등록번호: <b>{{ selectedPatentData.patentRegistNumber }}</b>
        </div>
        <br /> -->
        <span class="info_text">NTIS 정보</span>
        <table class="wf__patent-search-result-table">
          <colgroup>
            <col width="10%" />
            <col width="40%" />
            <col width="10%" />
            <col width="40%" />
          </colgroup>
          <tbody>
            <tr>
              <th>출원등록명</th>
              <td>
                <input type="text" name="patentResultTitle" v-model="selectedPatentData.patentResultTitle" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentResultTitle }}
                </span> -->
              </td>
              <th>출원등록인</th>
              <td>
                <input type="text" name="patentRegistrant" v-model="selectedPatentData.patentRegistrant" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentRegistrant }}
                </span> -->
              </td>
            </tr>
            <tr>
              <th>출원등록국</th>
              <td>
                <input type="text" name="patentRegistCountry" v-model="selectedPatentData.patentRegistCountry" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentRegistCountry }}
                </span> -->
              </td>
              <th>출원등록번호</th>
              <td>
                <input type="text" name="patentRegistNumber" v-model="selectedPatentData.patentRegistNumber" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentRegistNumber }}
                </span> -->
              </td>
            </tr>
            <tr>
              <th>성과연도</th>
              <td>
                <input type="text" name="patentYear" v-model="selectedPatentData.patentYear" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentYear }}
                </span> -->
              </td>
              <th>지재권구분</th>
              <td>
                <input type="text" name="patentRegistType" v-model="selectedPatentData.patentRegistType" disabled />
                <!-- <span class="wf__patent-search-result-input">
                  {{ selectedPatentData.patentRegistType }}
                </span> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <!-- 검색 구역 끝 -->

      <!-- 입력 정보 -->
      <span class="info_text">추가입력정보</span>
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>출원등록일자</th>
            <td><input type="date" name="patentRegistDate" v-model="userInputData.patentRegisterDate"
                placeholder="출원등록일자" /></td>
            <th>적용가능분야</th>
            <td><input type="text" name="patentApplyField" v-model="userInputData.patentApplyField"
                placeholder="적용가능분야" /></td>
          </tr>
          <tr>
            <th>개발인력</th>
            <td><input type="text" name="patentManpower" v-model="userInputData.patentManPower" placeholder="개발인력" /></td>
            <th>기술 성숙도 단계</th>
            <td>
              <select name="patentGrowLevel" id="patentGrowLevel" v-model="userInputData.patentGrowLevel">
                <option value="select" selected>선택</option>
                <option value="1">1단계</option>
                <option value="2">2단계</option>
                <option value="3">3단계</option>
                <option value="4">4단계</option>
                <option value="5">5단계</option>
                <option value="6">6단계</option>
                <option value="7">7단계</option>
                <option value="8">8단계</option>
                <option value="9">9단계</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>요약정보</th>
            <td colspan="3">
              <textarea type="text" name="patentSummary" v-model="userInputData.patentSummary"
                placeholder="요약정보"></textarea>
            </td>
          </tr>
          <tr>
            <th>파일 첨부</th>
            <td>
              <input type="file" ref="attachFile" />
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 버튼 구역 -->
      <div class="btnArea">
        <button type="button" class="e-btn wf__btn btn-primary" @click="goPatentList()">목록</button>
        <button type="button" id="savePatentBtn" class="e-btn wf__btn btn-success ml-2" @click="savePatent">저장</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
:deep(.input-wrapper) {
  font-size: 14px;
}

:deep(.input-wrapper input) {
  font-size: inherit;
}

.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}

input[readonly] {
  background-color: var(--wf-gray-600);
  color: var(--wf-gray-color);
}

.wf__patent-search-result-table input {
  /* display: none; */
}

.wf__patent-search-table input {
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
}

#patentCreateForm {
  display: flex;
  /* 하위 요소 가로 배치 */
  flex-direction: column;
  /* 배치 방향 설정 */
  flex-wrap: wrap;
  /* 줄넘김 처리. nowrap: 넘치면 삐져나감. wrap: 줄넘김 */
  align-content: stretch;
  /* 아이템들의 행이 2줄 이상 되었을 때 수직축 방향 결정*/
  justify-content: flex-start;
  /* 메인축 방향 정렬 */
  align-items: flex-start;
  /* 수직축 방향 정렬 */
}

#patentCreateForm textarea {
  width: 540px;
  height: 100px;
}

/* 연구실 번호 */
#labNoArea {
  margin: 10px 10px;
}

.flex-td {
  display: flex;
  gap: 1.25rem;
}

.input-wrapper {
  display: flex;
  align-items: stretch;
}

.paperSearchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--wf-primary-color);
  background-color: var(--wf-primary-color);
  color: var(--wf-misty-color);
}

/* 검색 구역 */
.patentSearch {
  display: flex;
  /* 하위 요소 가로 배치 */
  flex-wrap: wrap;
  /* 줄넘김 처리. nowrap: 넘치면 삐져나감. wrap: 줄넘김 */
  align-items: center;
  /* 아이템들의 행이 2줄 이상 되었을 때 수직축 방향 결정*/
  justify-content: flex-start;
  /* 메인축 방향 정렬 */
  margin: 10px 10px;
}

/* 기술 성숙도 selectBox*/
#patentGrowLevel {
  width: 150px;
  height: 40px;
  border: 1px solid #ededed;
  -webkit-appearance: none;
  /* 화살표 제거 for chrome */
  -moz-appearance: none;
  /* 화살'표 제거 for firefox */
  appearance: none;
  background: url('../../../../assets/images/icons/downArrowIcon.png') calc(100% - 5px) center no-repeat;
  background-size: 20px;
  padding: 5px 30px 5px 10px;
  color: #acacac;
  font-weight: 500;
}

#patentGrowLevel>option {
  background: #fff;
  color: #18a1e9;
  padding: 3px 0;
}

.btnArea {
  padding-top: 30px;
}
</style>
